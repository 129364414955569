import {
  Component,
  OnInit,
  HostBinding,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { LayoutConfigService } from "../../../../core/services/layout-config.service";
import * as objectPath from "object-path";
import { Router } from "@angular/router";
import { ApiService, ApiServiceConfig } from "../../../../api/api.module";

@Component({
  selector: "m-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements OnInit, AfterViewInit {
  @HostBinding("id") id = "m_header_nav";
  @HostBinding("class")
  classes = "m-stack__item m-stack__item--fluid m-header-head";
  hospitalsKey: Object[];
  currentHospital: Object;
  showHospital: boolean = false;
  oldHospitalId: string;

  @Input() searchType: any;

  constructor(
    private _apiService: ApiService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.hospitalsKey = JSON.parse(localStorage.getItem("hospitals"));
    //Solo in fase di Login
    if (!this.hospitalsKey) {
      this.me();
    } else {
      this.currentHospital = JSON.parse(
        localStorage.getItem("current_hospital")
      );
    }
  }

  ngOnInit(): void {}

  setSelect(a, b) {
    return a && b && a.id == b.id;
  }

  me() {
    this._apiService.getHospitals().subscribe(
      (res) => {
        this.hospitalsKey = res[0];
        if (!this.currentHospital) {
          this.currentHospital = this.hospitalsKey[0];
        }
        this.oldHospitalId = res[0][0].id;
        this.cd.detectChanges();
      },
      (error) => {}
    );
  }

  changeHeader(hospital) {
    if (hospital.id == this.oldHospitalId || !hospital) {
      return;
    }
    let config = new ApiServiceConfig();
    config = this._apiService.authConfig;
    config.globalOptions = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Hospital: hospital.id,
      },
    };
    this._apiService.init(config);
    localStorage.setItem("current_hospital", JSON.stringify(hospital));
    if (hospital.id != this.oldHospitalId) {
      this.oldHospitalId = hospital.id;
      location.reload();
      //this.router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.cd.detach();
  }
}
