import { ConfigModel } from "../core/interfaces/config";
import { PathPage } from "../utils/path-page";

export class MenuConfig implements ConfigModel {
  public config: any = {};

  constructor() {
    this.config = {
      header: {
        self: {},
        items: [],
      },
      aside: {
        self: {},
        items: [
          {
            section: "",
          },
          {
            title: "Documenti",
            translate: "MENU.MEDICAL_RECORDS",
            page: `/${PathPage.MEDICAL_RECORDS}`,
            root: true,
            icon: "fa fa-folder-open",
            toggle: "click",
          },
          {
            title: "Richieste",
            translate: "MENU.REQUESTS",
            page: `/${PathPage.REQUESTS}`,
            root: true,
            icon: "la la-folder-open",
            toggle: "click",
          },
          {
            title: "Impostazioni",
            translate: "MENU.SETTINGS",
            icon: "flaticon-settings-1",
            root: true,
            page: `/${PathPage.SETTINGS}`,
          },
          {
            title: "Profilo",
            translate: "MENU.PROFILE",
            page: `/${PathPage.PROFILE}`,
            root: true,
            icon: "flaticon-profile-1",
            toggle: "click",
          },
        ],
      },
    };
  }
}
