import { filter } from 'rxjs/operators';
import { Component, ElementRef, HostBinding, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from '../../../../../core/services/translation.service';
import { NavigationStart, Router } from '@angular/router';
import { ApiService } from '../../../../../api/api.service';

interface LanguageFlag {
	lang: string;
	country: string;
	flag: string;
	active?: boolean;
}

@Component({
	selector: 'm-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
	// tslint:disable-next-line:max-line-length
	@HostBinding('class') classes = 'm-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--header-bg-fill m-dropdown--mobile-full-width';
	@HostBinding('attr.m-dropdown-toggle') mDropdownToggle = 'click';
	language: LanguageFlag;
	languages: LanguageFlag[] = [
		{
			lang: 'it',
			country: 'Italiano',
			flag: 'assets/app/media/img/flags/01-italy.svg'
		},
		{
			lang: 'fr',
			country: 'Français',
			flag: 'assets/app/media/img/flags/03-france.svg'
		}
	];

	constructor(
		private translationService: TranslationService,
		private router: Router,
		private el: ElementRef,
		private cdr: ChangeDetectorRef,
		private apiService: ApiService
	) {}

	ngOnInit() {
		this.setSelectedLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});
	}

	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});
		setTimeout(() => {
			this.cdr.detectChanges();
		}, 500);
		let tmp_lng = localStorage.getItem('language');
		if(lang != tmp_lng){
			window.location.reload();
		}
		this.translationService.setLanguage(lang);
		(<DOMTokenList>this.el.nativeElement.classList).remove('m-dropdown--open');
		this.apiService.resetHeader();
	}

	setSelectedLanguage(): any {
		this.translationService.getSelectedLanguage().subscribe(lang => {
			if (lang) {
				setTimeout(() => {
					this.setLanguage(lang);
				});
			}
		});
	}

	getUrlFlag(){
		if(this.language && this.language.flag){
			return this.language.flag
		}else{
			let tmp = localStorage.getItem('language');
			if(tmp){
				if(tmp == 'fr'){
					return 'assets/app/media/img/flags/03-france.svg'
				}
				else if(tmp == 'it'){
					return 'assets/app/media/img/flags/01-italy.svg'
				}else{
					return 'assets/app/media/img/flags/01-italy.svg'
				}
			}
			else{
				return 'assets/app/media/img/flags/01-italy.svg'
			}
		}
	}
}
