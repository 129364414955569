import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { ApiService } from "../../../../../api/api.service";

@Component({
  selector: "m-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit {
  @HostBinding("class")
  // tslint:disable-next-line:max-line-length
  classes =
    "m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light";

  @HostBinding("attr.m-dropdown-toggle") attrDropdownToggle = "click";

  @Input() avatar: string = "./assets/app/media/img/users/user4.jpg";
  @Input() avatarBg: SafeStyle = "";

  @ViewChild("mProfileDropdown") mProfileDropdown: ElementRef;
  username: string;
  email: string;

  constructor(
    private _apiService: ApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.username = localStorage.getItem("username");
    this.email = localStorage.getItem("uid");

    if (!this.avatarBg) {
      this.avatarBg = this.sanitizer.bypassSecurityTrustStyle(
        "url(./assets/app/media/img/misc/user_profile_bg.jpg)"
      );
    }

    this.getUserInfo();
  }

  getUserInfo() {
    this._apiService.getUser().subscribe(
      (res) => {
        this.username = res.name;
        this.cd.detectChanges();
      },
      (error) => {}
    );
  }

  public logout() {
    this._apiService.signOut();
  }
}
