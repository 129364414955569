import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { PathPage } from "../utils/path-page";
import { ApiService } from "./api.service";

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: ApiService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let expectedRole = route.data.expectedRole;

    let role = localStorage.getItem("userRole");
    if (role === "ospedale_direzione") {
      role = "direzione";
    } else if (role === "normadec_frontoffice") {
      expectedRole = `normadec_frontoffice`;
    } else if (role === "admin") {
      expectedRole = `admin`;
    } else if (role === `ospedale_ufficio_cartelle_cliniche`) {
      expectedRole = `ospedale_ufficio_cartelle_cliniche`;
    }
    if (expectedRole != role) {
      this.router.navigate([`/${PathPage.AUTH}`]);
      return false;
    }
    return true;
  }
}
