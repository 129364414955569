export class PathPage {
  public static readonly SLASH: string = "/";

  public static readonly LOGIN: string = "login";
  public static readonly REGISTER: string = "register";
  public static readonly HOME: string = "home";
  public static readonly MEDICAL_RECORDS: string = "medical-records";
  public static readonly MEDICAL_RECORD_DETAIL: string =
    "medical-record-detail";
  public static readonly REQUESTS: string = "requests";
  public static readonly SETTINGS: string = "settings";
  public static readonly PROFILE: string = "profile";
  public static readonly AUTH: string = "auth";
  public static readonly EMAIL_VERIFICATION: string = "email-verification";
  public static readonly NOT_FOUND: string = "not-found";
  public static readonly FORGOT_PASSWORD: string = "forgot-password";
  public static readonly PRIVACY_POLICY: string = "privacy-policy";
  public static readonly TERMS_AND_CONDITIONS: string = "terms-and-conditions";

  public static readonly UNAUTHORIZED: string = "unauthorized";
}
