import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { from as fromPromise, Observable, throwError } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { PathPage } from "src/app/utils/path-page";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return fromPromise(this.handleAccess(req, next));
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    let token = null;
    let language = "en";
    let client = null;
    let expiry = null;
    let uid = null;
    let hospital = null;
    if (this.localStorageService.get("accessToken")) {
      token = this.localStorageService.get("accessToken");
    }
    if (this.localStorageService.get("language")) {
      language = this.localStorageService.get("language");
    }
    if (this.localStorageService.get("client")) {
      client = this.localStorageService.get("client");
    }
    if (this.localStorageService.get("expiry")) {
      expiry = this.localStorageService.get("expiry");
    }
    if (this.localStorageService.get("uid")) {
      uid = this.localStorageService.get("uid");
    }
    if (this.localStorageService.get("current_hospital")) {
      hospital = JSON.parse(this.localStorageService.get("current_hospital"));
    }
    let changedRequest = request;
    const headerSettings: { [name: string]: string | string[] } = {};
    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    headerSettings["Content-Type"] = "application/json";
    if (token) {
      headerSettings["access-token"] = `${token}`;
      headerSettings["token-type"] = "Bearer";
    }
    if (language) {
      headerSettings["language-code"] = language;
    }
    if (client) {
      headerSettings["client"] = client;
    }
    if (expiry) {
      headerSettings["expiry"] = expiry;
    }
    if (uid) {
      headerSettings["uid"] = uid;
    }
    if (hospital) {
      headerSettings["Hospital"] = hospital.id;
    }

    const newHeader = new HttpHeaders(headerSettings);
    changedRequest = request.clone({
      headers: newHeader,
    });
    return next
      .handle(changedRequest)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                this.localStorageService.remove("accessToken");
                this.router.navigate([`/${PathPage.AUTH}/${PathPage.LOGIN}`]);
                break;

              default:
                this.localStorageService.remove("accessToken");
                this.router.navigate([`/${PathPage.AUTH}/${PathPage.LOGIN}`]);
                break;
            }
          }

          return throwError(error);
        })
      )
      .toPromise();
  }
}
