import { ApiService, ApiServiceConfig } from "../api.service";
import { SignInData } from "angular2-token";
import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  Inject,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import * as objectPath from "object-path";
import { Subject } from "rxjs";
import { SpinnerButtonOptions } from "../../content/partials/content/general/spinner-button/button-options.interface";
import { TranslateService } from "@ngx-translate/core";
import { AuthNoticeService } from "../../api/auth-notice/auth-notice.service";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBarConfig,
  MatSnackBar,
} from "@angular/material";
import { PathPage } from "src/app/utils/path-page";
//import {MatSnackBar, MatSnackBarConfig} from '@angular/material';

export interface CustomSignIn extends SignInData {
  code: string;
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  public _signInData: CustomSignIn = <CustomSignIn>{};
  firstname: string;
  lastname: string;
  userId: string;
  sms_code: string;
  twoFactor: boolean = false;
  @Input() action: string;
  @ViewChild("f") f: NgForm;
  @ViewChild("f1") f1: NgForm;
  @HostBinding("class") classes: string = "m-login__signin";
  @Output() actionChange = new Subject<string>();

  public loading = false;
  errors: any = [];
  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: "light-blue",
    spinnerColor: "light",
    fullWidth: true,
  };
  spinner1: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: "light-blue",
    spinnerColor: "light",
    fullWidth: true,
  };

  constructor(
    private _apiService: ApiService,
    private router: Router,
    public snackBar: MatSnackBar,
    public authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  submit() {
    const _self = this;
    this.spinner.active = true;
    if (this.validate(this.f)) {
      this._apiService.signIn(this._signInData).subscribe(
        (res) => {
          _self.cdr.detectChanges();
          _self.spinner.active = false;
          _self.authNoticeService.setNotice(``, "");
          _self._apiService.getHospitals().subscribe(
            (res) => {
              if (res && res[0].length > 0) {
                localStorage.setItem("hospitals", JSON.stringify(res[0]));
                localStorage.setItem(
                  "current_hospital",
                  JSON.stringify(res[0][0])
                );
                _self.router.navigate([`/${PathPage.MEDICAL_RECORDS}`]);
              }
            },
            (error) => {}
          );
        },
        (error) => {
          _self.spinner.active = false;
          if (error.json().errors) {
            if (error.json().errors.email) {
              _self.authNoticeService.setNotice(
                error.json().errors.email,
                "error"
              );
            } else if (error.json().errors.password) {
              _self.authNoticeService.setNotice(
                error.json().errors.password,
                "error"
              );
            } else {
              _self.authNoticeService.setNotice(
                error.json().errors[0],
                "error"
              );
            }
          }
          _self.cdr.detectChanges();
        }
      );
    }
  }

  validateTwoFactor() {
    if (this.validateF1(this.f1)) {
      this.spinner1.active = true;
      this._apiService.validateTwoFactor(this.sms_code).subscribe(
        (res) => {
          this.me(res.json().user.role);
          this.authNoticeService.setNotice(``, "");
          this.redirectUser(res.role);
        },
        (error) => {
          this.spinner.active = false;
          this.spinner1.active = false;
          this.sms_code = "";
          this.showSnackBarError("Codice non valido");
          this.twoFactor = false;
          this.cdr.detectChanges();
        }
      );
    }
  }

  redirectUser(role) {
    if (role.split("_")) {
      this.router.navigateByUrl(role.split("_")[1]);
    }
  }

  me(role) {
    this._apiService.getHospitals().subscribe(
      (res) => {
        if (res && res[0].length > 0) {
          localStorage.setItem("hospitals", JSON.stringify(res[0]));
          localStorage.setItem("current_hospital", JSON.stringify(res[0][0]));

          let config = new ApiServiceConfig();
          config = this._apiService.authConfig;
          config.globalOptions = {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Hospital: res[0][0].id,
            },
          };
          this._apiService.init(config);
          this.actionChange.next(this.action);

          this.redirectUser(role);
        }
      },
      (error) => {}
    );
  }

  ngOnInit() {}

  validate(f: NgForm) {
    if (f.form.status === "VALID") {
      return true;
    }

    this.errors = [];
    if (objectPath.get(f, "form.controls.email.errors.email")) {
      this.errors.push("L'email non è valida");
    }
    if (objectPath.get(f, "form.controls.email.errors.required")) {
      this.errors.push("L'email è obbligatoria");
    }

    if (objectPath.get(f, "form.controls.password.errors.required")) {
      this.errors.push("La password è obbligatoria");
    }
    if (objectPath.get(f, "form.controls.password.errors.minlength")) {
      this.errors.push("La password deve avere almeno 8 caratteri");
    }

    if (this.errors.length > 0) {
      this.authNoticeService.setNotice(this.errors.join("<br/>"), "error");
      this.spinner.active = false;
    }

    return false;
  }

  validateF1(f1: NgForm) {
    if (f1.form.status === "VALID") {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  showSnackBar(msg) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = "success";
    // config.duration = 6000;
    this.snackBar.open(msg, "OK", config);
  }

  showSnackBarError(msg) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = "error";
    config.duration = 5000;
    this.snackBar.open(msg, "OK", config);
  }

  openDialogBox() {
    const dialogRef = this.dialog.open(DialogResetPwd, {
      width: "20vw",
      data: {},
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this._apiService
            .requestPasswordReset(
              "http://" + window.location.host + "/auth/reset-password",
              result
            )
            .subscribe(
              (res) => {
                this.showSnackBar(res.message);
              },
              (error) => {}
            );
        }
      },
      (error) => {}
    );
  }
}

@Component({
  selector: "dialog-reset-pwd",
  template:
    '<h1 mat-dialog-title>Password dimenticata?</h1>\
    <div mat-dialog-content>\
      <p>Ti verrà inviata un\'email con le istruzioni per reimpostare la password.</p>\
    </div>\
    <div mat-dialog-actions>\
    <mat-label>Inserisci Codice Fiscale</mat-label>\
    <input class="form-control" type="email" [(ngModel)]="email" required>\
      <div class="btn-wrapper text-right action">\
          <button type="submit" class="btn btn-accent mr-2" [mat-dialog-close]="email" cdkFocusInitial [disabled]="!email">Conferma</button>\
          <button type="reset" class="btn btn-secondary" (click)="onNoClick()">Annulla</button>\
      </div>\
    </div>',
  styles: [
    ".mat-dialog-title{color: #575962;font-size: 17px;} .action{margin:20px 0}",
  ],
})
export class DialogResetPwd {
  email: string;

  constructor(
    public dialogRef: MatDialogRef<DialogResetPwd>,
    @Inject(MAT_DIALOG_DATA) public data: DialogResetPwd
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
