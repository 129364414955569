import { AuthNoticeService } from "./../auth-notice/auth-notice.service";
import { ApiService, ApiServiceConfig } from "./../api.service";
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  Input,
} from "@angular/core";
import { SpinnerButtonOptions } from "../../content/partials/content/general/spinner-button/button-options.interface";
import { NgForm } from "@angular/forms";
import * as objectPath from "object-path";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material";

@Component({
  selector: "m-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild("f") f: NgForm;
  @ViewChild("f1") f1: NgForm;
  @Output() actionChange = new Subject<string>();
  @Input() action: string;

  spinner: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: "light-blue",
    spinnerColor: "light",
    fullWidth: false,
  };

  spinner1: SpinnerButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    buttonColor: "light-blue",
    spinnerColor: "light",
    fullWidth: false,
  };

  twoFactor: boolean = false;

  userId: string;

  password: any;
  password_confirmation: any;
  errors: any[];
  sms_code: string;

  constructor(
    private _apiService: ApiService,
    private authNoticeService: AuthNoticeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem(`userId`);
  }

  submit() {
    this.spinner.active = true;
    let user = {
      user: {
        password: this.password,
        password_confirmation: this.password_confirmation,
      },
    };
    if (this.validate(this.f)) {
      this._apiService.changePassword(user, this.userId).subscribe(
        (res: any) => {
          if (res.user["two_factor_authentication_required"]) {
            this.twoFactor = true;
            this.spinner.active = false;
            this.authNoticeService.setNotice(``, "");
            this.cdr.detectChanges();
          } else {
            localStorage.setItem(`force_change_password`, `false`);
            this.me(res.user.role);
            this.cdr.detectChanges();
            this.authNoticeService.setNotice(``, "");
          }
        },
        (error) => {
          this.authNoticeService.setNotice(error.json().errors[0], "error");
          this.spinner.active = false;
          this.cdr.detectChanges();
        }
      );
    }
  }

  validate(f: NgForm) {
    this.errors = [];

    if (objectPath.get(f, "form.controls.password.errors.required")) {
      this.errors.push("La password è obbligatoria");
    }
    if (objectPath.get(f, "form.controls.password.errors.minlength")) {
      this.errors.push("La password deve avere almeno 8 caratteri");
    }

    if (this.password_confirmation !== this.password) {
      this.errors.push(
        `La password di conferma deve essere uguale alla password`
      );
    }

    if (this.errors.length > 0) {
      this.authNoticeService.setNotice(this.errors.join("<br/>"), "error");
      this.spinner.active = false;
      return false;
    }

    if (f.form.status === "VALID") {
      return true;
    }
  }

  validateTwoFactor() {
    if (this.validateF1(this.f1)) {
      this.spinner1.active = true;
      this._apiService.validateTwoFactor(this.sms_code).subscribe(
        (res) => {
          localStorage.setItem(`force_change_password`, `false`);
          this.authNoticeService.setNotice(``, "");
          this.me(res.user.role);
          this.redirectUser(res.role);
        },
        (error) => {
          this.spinner.active = false;
          this.spinner1.active = false;
          this.sms_code = "";
          this.showSnackBarError("Codice non valido");
          this.twoFactor = false;
          this.cdr.detectChanges();
        }
      );
    }
  }

  validateF1(f1: NgForm) {
    if (f1.form.status === "VALID") {
      return true;
    }
    return false;
  }

  redirectUser(role) {
    if (role.split("_")) {
      this.router.navigateByUrl(role.split("_")[1]);
    }
  }

  me(role) {
    this._apiService.getHospitals().subscribe(
      (res) => {
        if (res && res[0].length > 0) {
          localStorage.setItem("hospitals", JSON.stringify(res[0]));
          localStorage.setItem("current_hospital", JSON.stringify(res[0][0]));

          let config = new ApiServiceConfig();
          config = this._apiService.authConfig;
          config.globalOptions = {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Hospital: res[0][0].id,
            },
          };
          this._apiService.init(config);
          this.actionChange.next(this.action);
          this.redirectUser(role);
        }
      },
      (error) => {}
    );
  }

  showSnackBarError(msg) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = "error";
    config.duration = 5000;
    this.snackBar.open(msg, "OK", config);
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
