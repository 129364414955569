import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor (private router: Router) {
  }
  canActivate() {
    let force_change_password = localStorage.getItem(`force_change_password`);
    if (force_change_password === `true`) {
      this.router.navigate([`/auth/cambia-password`])
      return false;
    }
    return true;
  }
}
