import { ConfigModel } from "../core/interfaces/config";
import { PathPage } from "../utils/path-page";

export class PagesConfig implements ConfigModel {
  public config: any = {};

  constructor() {
    this.config = {
      [PathPage.MEDICAL_RECORDS]: {
        page: {
          title: "Documenti",
          translate: "MENU.MEDICAL_RECORDS",
          desc: "Documenti",
        },
      },
      [PathPage.MEDICAL_RECORD_DETAIL]: {
        page: {
          title: "Dettaglio documento",
          translate: "PAGES.MEDICAL_RECORD_DETAIL.TITLE",
          desc: "Dettaglio documento",
        },
      },
      [PathPage.REQUESTS]: {
        page: {
          title: "Richieste",
          translate: "MENU.REQUESTS",
          desc: "Richieste",
        },
      },
      [PathPage.SETTINGS]: {
        page: {
          title: "Impostazioni",
          translate: "MENU.SETTINGS",
          desc: "Impostazioni",
        },
      },
      [PathPage.PROFILE]: {
        page: {
          title: "Profilo",
          translate: "MENU.PROFILE",
          desc: "Profilo",
        },
      },
      builder: {
        page: { title: "Layout Builder", desc: "Layout builder" },
      },
      header: {
        actions: {
          page: { title: "Actions", desc: "actions example page" },
        },
      },
      404: {
        page: { title: "404 Not Found", desc: "", subheader: false },
      },
    };
  }
}
