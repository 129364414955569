import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  Renderer2,
  Injectable,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";

import { NgModel } from "@angular/forms";
import * as moment from "moment";
import { Subscription } from "rxjs";

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

const I18N_VALUES = {
  it: {
    weekdays: ["Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do"],
    months: [
      "Gen",
      "Feb",
      "Mar",
      "Apr",
      "Mag",
      "Giu",
      "Lug",
      "Ago",
      "Set",
      "Ott",
      "Nov",
      "Dic",
    ],
  },
  // other languages you would support
};
@Injectable()
export class I18n {
  language = "it";
}

@Component({
  selector: "ngbd-daterange-picker",
  templateUrl: "./daterange-picker.component.html",
  styles: [
    `
      .btn-link {
        color: #00c5dc;
      }
      .ngb-dp-day {
        outline: none;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
        outline: none;
      }
      .custom-day.focused {
        background-color: #00c5dc;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: #00c5dc;
        color: white;
        outline: none;
      }
      .custom-day.faded {
        background-color: rgba(0, 197, 220, 0.5);
      }
    `,
  ],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: NgbdDaterangePicker },
  ],
})
@Injectable()
export class NgbdDaterangePicker extends NgbDatepickerI18n implements OnInit {
  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  model: any;
  @ViewChild("d") input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild("myRangeInput") myRangeInput: ElementRef;
  @Input("folder") folder: Object;
  @Input("type") type: string;

  isHovered = (date) =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate);
  isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = (date) => equals(date, this.fromDate);
  isTo = (date) => equals(date, this.toDate);

  constructor(
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter,
    private _i18n: I18n
  ) {
    super();
  }
  ngOnInit() {
    this.startDate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  onDateSelection(date: NgbDateStruct) {
    let parsed = "";
    if (
      this.folder &&
      this.folder["from_" + this.type] &&
      this.folder["to_" + this.type]
    ) {
      this.folder["from_" + this.type] = "";
      this.folder["to_" + this.type] = "";
    }
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate && this.type) {
      parsed += this._parserFormatter.format(this.fromDate);
      let mdt = moment([
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day,
      ]);
      if (!mdt.isValid()) return "";
      this.folder["from_" + this.type] = mdt.format("YYYY-MM-DD");
    }
    if (this.toDate && this.type) {
      parsed += " - " + this._parserFormatter.format(this.toDate);
      let mdt = moment([
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day,
      ]);
      if (!mdt.isValid()) return "";
      this.folder["to_" + this.type] = mdt.format("YYYY-MM-DD");
    }

    this.renderer.setProperty(this.myRangeInput.nativeElement, "value", parsed);
  }

  reset() {
    this.model = "";
    this.folder["from_" + this.type] = "";
    this.folder["to_" + this.type] = "";
  }
}
