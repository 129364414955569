import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class PreviousRouteService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private localstorageService: LocalStorageService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
}
