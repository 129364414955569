// FR
export const locale = {
  lang: "fr",
  data: {
    MENU: {
      MEDICAL_RECORDS: "Dossiers",
      REQUESTS: "Demandes",
      SETTINGS: "Paramètres",
      PROFILE: "Profil",
    },
    PAGES: {
      MEDICAL_RECORDS: {
        DROPDOWN_MENU: {
          PAPER_COPY: "Demander une copie papier",
          DIGITAL_COPY: "Demander une copie digital",
          SHARE: "Partager",
          PRINT: "Presse",
          MANAGE_VISIBILITY: "Gérer la visibilité",
        },
        FILTER: {
          YEAR: "Année du dossier",
          FOLDER_NUMBER: "N.ro de dossier",
          RICOVER_DATE: "Date d'admission",
          DISCHARGE_DATE: "Date de sortie",
          HAS_PDF: "Noumérisè",
        },
      },
      MEDICAL_RECORD_DETAIL: {
        TITLE: "Détails du dossier clinique",
      },
    },
    BUTTON: {
      ORDER: "Commande",
      SEE: "Voir",
      SEE_RETREATS: "Voir les retraites",
      CONFIRMATION: "Confirmation",
      CANCEL: "Annuler",
      SHOW: "Voir",
      SHOW_ALL: "Voir toutes",
      SEARCH: "Recherche",
      EDIT: "Modifier",
      PRINT_FOLDER: "Imprimer dossier",
      PRINT_DOCUMENTS: "Imprimer les documents",
      PRINT_FOLDERS: "Imprimer dossiers",
      DOWNLOAD_FOLDERS: "Télécharger des dossiers",
      DOWNLOAD_FOLDER: "Télécharger de dossier",
      PRINT_INVOICES: "Imprimer les factures",
      PRINT_ADDRESS: "Imprimer l'adresse / I.",
      CHANGE_STATE: "Changer le statut",
      CANCEL_REQUEST: "Demande d'annulation",
      REMOVE_FOLDER: "Supprimer le dossier",
      SHOW_DETAIL: "Voir les détails",
      SAVE: "Enregistrer",
      REMOVE: "Supprimer",
      CONTINUE: "Continuer",
      CANCEL_CHANGED: "Annuler les modifications",
      CONFIRM_DELITION: "Confirmer la suppression",
      DOWNLOAD_PDF: "Télécharger le PDF",
      PRINT_ALL: "Tout imprimer",
      DIAGNOSTICS: "Diagnostique",
    },
    AUTH: {
      LOGIN: {
        TITLE: "Se connecter",
        BUTTON: "Login",
      },
      FORGOT: {
        TITLE: "Avez-vous oublié votre mot de passe?",
        DESC: "Un e-mail vous sera envoyé avec des instructions pour réinitialisez votre mot de passe.",
      },
      INPUT: {
        EMAIL: "E-mail",
        PASSWORD: "Mot de passe",
      },
    },
    DASHBOARD: {
      TITLE: "Dashboard",
      LAST_UPDATE: "Dernière mise à jour:",
      FIRST_BLOCK:
        "Dossiers médicaux à l'hôpital<br> pour les quelles une demande a déjà été faite",
      PATIENT_REQUESTS: {
        TITLE: "Demandes des patients faites",
        FIRST_LINE: "12 derniers mois",
        SECOND_LINE: "Demandes à satisfaire",
        HOSPITAL: {
          TITLE: "À l'hôpital",
          FIRST_LINE: "à Préparer",
          SECOND_LINE: "à rètirer",
        },
      },
      WITHDRAWAL: "Retraits programmés",
      REQUEST: "demande",
      REQUESTS: "demandes",
      NORMADEC_REQUESTS: {
        TITLE: "Demandes a Normadec",
        FIRST_LINE: "originaux",
        SECOND_LINE: "CD",
        THIRD_LINE: "nouveaux numerisation",
        FOURTH_LINE: "ajouts",
      },
      FOURTH_BLOCK: "Dossiers originaux <br>à renvoyer à Normadec",
      FIFTH_BLOCK: {
        TITLE: "Retraite suivante",
        FIRST_LINE: "Dossiers médicaux",
        SECOND_LINE: "dans",
        THIRD_LINE: "boîtes prêtes à l'emploi",
      },
      SIXTH_BLOCK: "Boîtes restantes",
    },
    REQUESTS: {
      TITLE: "Demandes de copie papier",
      PATIENT: "Patient",
      PATIENT_SURNAME: "Nom du patient",
      PATIENT_NAME: "Prenom du patient",
      PATIENT_TAX_CODE: "Code fiscal du patient",
      REQUESTED_PERIOD: "Période de demande",
      SOLVENT: "Solvant",
      SOLV: "Solv.",
      ALL: "Tous",
      ALL_F: "Tout",
      STATUS_REQUEST: "Statut de la demande",
      DELIVERY_TYPE: "Type de livraison",
      JUST_REMINDERS: "Rappels seulement",
      APPLICANT: "Demandeur",
      APPLICANT_SURNAME: "Nom du demandeur",
      APPLICANT_NAME: "Prenom du demandeur",
      NUMBER_REQUEST: "Numéro de demande",
      NUM_REQUEST: "N.ro de demande",
      MERGE_REQUESTS: "Fusionner les demandes",
      PRINT_REQUESTS: "Imprimer les demandes",
      SEND_REQUESTS: "Envoyer des demandes",
      REQUESTED_DATE: "Date demandée",
      DELIVERY: "Livraison",
      PRICE: "Prix",
      FOLDER_CODE: "Cod de dossier",
      FOLDER_CODES: "Codes de dossiers",
      NUMBER_PAGES: "Nombre des pages",
      PRIORITY: "Priorité",
      HIGH: "Haute",
      NORMAL: "Ordinaire",
      DOMICILE: "Domicile",
      OPERATOR: "Opérateur",
      CONTENT: "Contenu",
      POSITION: "Emplacement",
      RESCAN_FOLDER: "Nouveau numerisation du dossier",
      REQUEST_DETAIL: {
        TITLE: "Demande de détails",
        ID_REQUEST: "ID Demander",
        TYPE_REQUEST: "Type de demande",
        TERRITORIAL_UNITY: "Unité territoriale",
        INSURANCE: "Assurance",
        NOTE: "Notes",
        NO_NOTE: "Aucun note",
        AMOUNTS_DETAIL: "Détails des montants",
        SHIPMENT: "Livaison",
        TOTAL: "Nombre total",
        TOTAL_IVA: "Nombre total + TVA",
        BILLING_TYPE: "Type de facturation",
        BILLING_INFO: "Détails de facturation",
        PHONE: "Tel",
        MOBILE_PHONE: "Portable",
        ADDRESS: "Adresse",
        SHIPPING_DATA: "Détails de livraison",
      },
      MEDICAL_RECORDS: {
        "": "Dossiers Médicaux",
        TITLE: "Dossiers Médicaux",
        PAGES: "Pages",
        ACTUAL_POSITION: "Position actuelle",
        BOX_POSITION: "Position de la boîte",
        AISLE: "Rayon",
        ARCHIVE_CABINET: "Étagère",
        SHELF: "Étagère",
        WAREHOUSE: "Entrepôt",
        PALLET: "Palette",
      },
      OTHER_DOCUMENTS: "Autres documents",
      NO_DOCUMENTS: "Aucun document disponible",
      SHIPPING_DETAIL: "Détail de livraison",
      NO_DATA: "Pas des informations disponibles",
      LOG: "Journal d'activité",
      MEDICAL_FOLDER: "dossiers médicaux",
      MEDICAL_FOLDERS: "dossiers médicaux",
      COPY_MEDICAL_FOLDER: "copie des dossiers médicaux",
      RETURN_MEDICAL_FOLDER: "retour des dossiers médicaux",
      RISCAN_MEDICAL_FOLDER: "numérisation des dossiers médicaux",
      REQUEST_MEDICAL_FOLDER: "demander un dossier médical",
      REQUEST_CD: "demander un cd",
      ON_CD: "dans un CD",
      MEDICAL_RECORD_TO_BE_RESCAN: "Dossier médical à réanalyser",
      DESC_1:
        "Cette demande est adressée à l'hôpital. Les Factures et Les DDT sont traités de manière cumulative, fusionnant plusieurs demandes.",
      DESC_2: "Cliquez ici pour accéder à la zone de facturation",
      DESC_3: "Cliquez ici pour accéder à la zone DDT",
      CARRIER: "Courier",
      STATE: "Statut",
      REQUEST_SHIPPING: "Demander la livaison",
      NO_RESULTS_FOUND: "Aucun résultat trouvé",
      DELIVERY_METHOD_TYPE: {
        OSPEDALE: "Hôpital",
        DOMICILIO: "Domicile",
        DIGITALE: "Digital",
      },
    },
    NEW_REQUEST: {
      STEP_2: "Ajouter des dossiers médicaux à la demande",
      STEP_3: "Envoyer une demande",
      TITLE: "Récapitulatif de la demande",
      ADD: "Ajouter sélectionnée",
      BUTTON_DELETE: "Supprimer sélectionnée",
      EDIT_REQUEST: "Change la demande",
    },
    DIALOG_DELETE: {
      DESC: "Supprimer le dossier de la demande",
      QUEST_1: "Voulez-vous vraiment annuler la demande?",
      QUEST_2: "Voulez-vous vraiment supprimer le dossier?",
      ADD_NOTA: "Entrer une note",
    },
    DIALOG_PICKUP: {
      TITLE: "Détail du retrait",
      RETREAT_ON: "retraite du ",
      AT: "prés du",
      ORIGINAL_FOLDER: "dossiers originaux (retourner)",
      INTEGRATIONS: "Ajouts demandés",
      INTEGRATION: "Ajouts demandé",
      TO_BE_DELIVERED: "à livrer",
      COPY_MEDIAL_FOLDER: "copie des dossiers - Demande du patient",
      DESC: "Il n'y a pas encore de détail pour ce retrait",
      PRINT_DOCUMENT: "Imprimer le document de livraison",
    },
    DIALOG_BOX: {
      TEXT_1: "dossiers seront insérés dans la boîte",
      TEXT_2:
        "dossiers avec des informations ou des informations qui concernent le patient manquantes",
      TEXT_3:
        "dossiers avec association de boîte manquante (demande d'intervention)",
      TEXT_4: "dossiers déjà associé à une boîte ou une boîte fermée",
    },
    FOLDER: {
      FOLDER_DETAIL: "Détail du dossier",
      SEARCH_FOLDER: "Recherche de dossier",
      FOLDER_DATA: "Informations du dossier",
      ASSOCIATED_REQUEST: "Demande associée",
      ASSOCIATED_REQUESTS: "Demandes associées",
      FOLDER_CODE: "Code de dossier",
      HOSPITALIZATION_DATE: "Date d'admission",
      DISCHARGE_DATE: "Date de sortie",
      FOLDER_STATUS: "État du dossier",
      BOX_CODE: "Code de boîte",
      SCANNED: "Noumérisè",
      YEAR_FOLDER: "Année du dossier",
      NUM_FOLDER: "N.ro de dossier",
      NAME_SURNAME: "Prenom Nom",
      HOSP_DATE: "Date <br>d'admission",
      DISC_DATE: "Date de <br> sortie",
      LOCATION: "Emplacement",
      ORIGINAL_FOLDERS: "dossiers originaux",
      ORIGINAL_FOLDER: "dossier original",
      FOLDER_POSITION: "Emplacement du dossier",
      DISCHARGE_DEPARTMENT: "Département de décharge",
      NO_PDF: "Il n'y a pas de dossier PDF",
      DATE_OF_BIRTH: "Date de<br> naissance",
      ENTRY_DATE: "Date<br> d'entrée",
      TYPOLOGY: "Typologie",
      TYPE: {
        CARTELLA: "Dossier",
        REFERTO: "Rapport",
      },
    },
    PICKUP: {
      ADD_PICKUP: "Ajouter un retrait",
      BOXES_PICKUP: "N.ro de boîtes <br>à collectionne",
      FOLDERS_PICKUP: "N.ro de dossiers <br>à collecter",
      ORIGINAL_FOLDERS_PICKUP: "N.ro de dossiers originaux <br>à collecter",
      FOLDERS_DELIVERY: "N.ro de copies de dossiers <br>à livrer",
      BOXES_REQUIRED: "N.ro boîtes <br>requises",
      MANUAL_ENTRY: "Saisie manuelle",
      BOXES_PREPARATION: "Boîtes en préparation",
      BOXES_READY: "Boîtes prêtes pour le retrait",
      NEXT_DATE: "Prochaine date de retrait",
      NO_WITHDRAWALS: "Il n'y a pas de retrait programmés",
      PRINT: "Stampa documento ritiri",
      CONTAINED_FOLDERS: "Dossiers contenus",
      SAVE: "Enregistrer le contenu de la boîte",
    },
    COMMON: {
      FILTERS: "Filtres",
      HOSPITAL: "Hôpital",
      NUM_FOLDER: "Numéro de dossier",
      YEAR_FOLDER: "Année du dossier",
      FOLDER: "Dossier",
      FOLDERS: "Dossiers",
      BOX: "Boîte",
      BOXES: "Boîtes",
      ACTIONS: "Actions",
      ORPHAN: "orfanello",
      ORPHANS: "orfanelli",
      YES: "Oui",
      NO: "Non",
      INVOICE: "Facture",
      SURNAME: "Nom",
      NAME: "Prénom",
      DATE_OF_BIRTH: "Date de naissance",
      FISCAL_CODE: "Code fiscal",
      DATE: "Date",
      CONFIRMED_REQUEST: "Demande confirmée",
      CONTENT_REQUEST: "Contenu de la demande",
      SOLVENT_PATIENT: "Patient solvant",
      SELECT_INSTITUTION: "Sélectionnez une institution",
      DELIVERY_METHOD: "Modes de livraison",
      TERM_OF_PAYMENTS: "Modes de paiement",
      P0: "Marque",
      P1: "Au comptoir",
      P2: "Facturation en fin de mois",
      REQUIRED_PRIORITY: "Priorité de la demande",
      SHIPPING_DATA: "Détails de livraison",
      BILLING_INFORMATION: "Détails de facturation",
      DATA_APPLICANT: "Informations du demandeur (si n'est pas le patient)",
      DOCUMENT_TYPE: "Type de document",
      NUM_DOCUMENT: "Numéro de document",
      AS: "En tant que",
      COST_REQUEST: "Prix de la demande",
      TOT_FOLDERS: "Dossiers totaux",
      COST_SHIPPING: "Frais de livraison",
      REQUEST_SENT: "Demande envoyée",
      STEP_3_TEXT: "Votre demande a été envoyée avec le code",
      BACK_TO: "Retour à la liste des demandes",
      NEW_REQUEST: "Nouvelle requête",
      FOLDER_IMPORT_SUCCESS: "dossiers importés avec succès",
      LOAD_LIST: "Télécharger la liste",
      FOLDER_WITH_ERROR: "Dossiers contenant des erreurs",
      HOSPITAL_CODE: "Cod. de l'hôpital",
      DATA_FOLDER: "Dossiers de données",
      N_FOLDER: "N.ro dossiers",
      LOOK_DETAIL: "Voir le détail",
      SENDER_REMINDER: "Envoyer un rappel",
      DETAIL: "Détails",
      REQUESTS_LIST: "Liste des demandes",
      ADD_NOTA_TO_REQUEST: "Ajouter une note à la demande",
      SURE: "Voulez-vous vraiment envoyer un rappel?",
      VIEW_CALENDAR: "Vue du calendrier",
      VIEW_LIST: "Vue du liste",
      DELETE_BOX: "Supprimer la boîte",
      SURE_DELETE_BOX: "Sei sicuro di voler eliminare la scatola?",
      CLOSE_BOX: "Fermer la boîte",
      REOPEN_INSERTION: "Rouvrir l'insertion",
      SEE_FOLDERS: "Voir les dossiers",
      MOVE_TO_PROCESSING: "Passer au traitement",
      NEAT_BOXES: "Boîtes soignées",
      FAILED_ADD_FOLDER: "Échec de l'ajout du dossier",
      NO_ADD_FOLDER:
        "Dati del paziente non presenti. <br>La cartella non può essere aggiunta alla richiesta.",
      NO_SOLVENT_PATIENT:
        "Il n'est pas possible de définir «Solvant patient» s'il y a plus d'un dossier dans la demande.",
      DATA_SUMMARY: "Résumé des données",
      //CHECK_PATIENT_ALERT: 'Il peut y avoir des cas d\'homonymie. <b> Vérifiez le code fiscal. </b>',
      CHECK_PATIENT_ALERT:
        "Avant de confirmer l'insertion de la demande, vérifiez la correspondance des données, notamment le code fiscal et la date de naissance.",
      INTEGRATION: "Ajouts demandé",
      ATTENTION: "Attention",
    },
    USER: {
      USER_LIST: "Liste d'utilisateur",
      ADD_USER: "Ajouter un utilisateur",
      SURNAME: "Nom",
      NAME: "Prénom",
      PHONE: "Portable",
      ROLE: "Rôle",
      ACTIONS: "Actions",
      NO_USER_FOUND: "Aucun utilisateur trouvé",
      EDIT_USER: "Modifier l'utilisateur",
      DELETE_USER: "Supprimer l'utilisateur",
      USER_DATA: "Informations d'utilisateur",
      CREATE_USER: "Créer un utilisateur",
      UPDATE_USER: "Mettre à jour l'utilisateur",
      UDPATE_PROFILE: "Mettre à jour le profil",
      PHONE_ONLY_NUMBER: "Mobile (numéros uniquement)",
      CONFIRM_PASSWORD: "Confirmez le mot de passe",
      NATION: "Nation",
      CITY: "Ville",
      PROVINCE: "Province",
      REGION: "Région",
      TELEPHONE: "Téléphone",
      NOTE_PHONE: "Notes de téléphone",
      SAFE_TO_DELETE: "Êtes-vous sûr de vouloir supprimer l'utilisateur",
    },
    BOX_REQUEST: {
      TEXT_1: "Nombre de boîtes à l'hôpital",
      TEXT_2: "Nombre boîte commandée",
      TEXT_3: "Demander le nombre de boîtes",
    },
    REQUEST_HOSPITAL: {
      TEXT_1: "Liste des demandes des dossiers médicaux originaux",
      TEXT_2: "Liste des demandes des CD",
      TEXT_3: "Liste des demandes des nouveaux numerisation",
      TEXT_4: "Liste des demandes des ajouts",
      TEXT_5: "Liste des demandes des retour original",
      TEXT_6: "Liste des demandes des retour original (avec rescan)",
      TEXT_7: "Liste des demandes des boites",
      TEXT_8: "Liste des demandes d'intégration des orphelins",
    },
    DIALOG_MORE_FOLDER: {
      TITLE: "Demande de confirmation",
      TEXT: "Attention, vous faites une demande avec plus d'un dossier, <b> entrez une note avec la raison. </b>",
    },
  },
};
