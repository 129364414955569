import { Angular2TokenService } from "angular2-token";
import { Injectable, Inject } from "@angular/core";
import { SignInData } from "angular2-token";
import { UpdatePasswordData } from "angular2-token";
import { Observable } from "rxjs/Observable";
import { Response, Http } from "@angular/http";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslationService } from "../core/services/translation.service";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { CustomSignIn } from "./login/login.component";

export class ApiServiceConfig {
  apiBase?: string;
  signInPath?: string;
  signInStoredUrlStorageKey?: string;
  signOutPath?: string;
  validateTokenPath?: string;
  signOutFailedValidate?: boolean;
  registerAccountPath?: string;
  deleteAccountPath?: string;
  registerAccountCallback?: string;
  updatePasswordPath?: string;
  resetPasswordPath?: string;
  resetPasswordCallback?: string;
  oAuthPaths?: any;
  oAuthCallbackPath?: string;
  userTypes?: any;
  signedInRedirect?: string;
  signedOutRedirect?: string;
  signInCondition?: (res: Response) => boolean;
  globalOptions?: any;
}

@Injectable()
export class ApiService extends Angular2TokenService {
  private myRouter: Router;
  private hospitalsKey: Object[];

  constructor(
    private _http: Http,
    _activatedRoute: ActivatedRoute,
    router: Router,
    private translationService: TranslationService
  ) {
    super(_http, _activatedRoute, router);
    this.myRouter = router;
  }

  authConfig: ApiServiceConfig;

  get currentUser() {
    return this.currentUserData;
  }

  init(config: ApiServiceConfig): void {
    this.authConfig = config;
    if (config.signInCondition) {
      this.authConfig.signInCondition = config.signInCondition;
    } else {
      this.authConfig.signInCondition = (res: Response) => {
        return true;
      };
    }

    super.init({
      apiBase: config.apiBase || "http://localhost:4200",
      apiPath: null,

      signInPath: config.signInPath || "oauth/code",
      signInRedirect: "home",
      signInStoredUrlStorageKey: config.signInStoredUrlStorageKey || null,

      signOutPath: config.signOutPath || "v1/auth/logout",
      validateTokenPath: config.validateTokenPath || "v1/auth/validate_token",
      signOutFailedValidate: true,

      registerAccountPath: config.registerAccountPath || "v1/auth/register",
      deleteAccountPath: config.deleteAccountPath || "v1/auth/delete_account",
      registerAccountCallback:
        config.registerAccountCallback || window.location.href,

      updatePasswordPath: config.updatePasswordPath || "v1/auth/password",
      resetPasswordPath: config.resetPasswordPath || "v1/auth/password",
      resetPasswordCallback:
        config.resetPasswordCallback || window.location.href,

      oAuthPaths: config.oAuthPaths || {
        facebook: "v1/auth/facebook",
      },

      oAuthCallbackPath: config.oAuthCallbackPath || "oauth_callback",
      oAuthWindowType: "newWindow",
      oAuthWindowOptions: null,

      userTypes: config.userTypes || [
        { name: "user", path: "user" },
        { name: "employee", path: "" },
      ],

      globalOptions: config.globalOptions || {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Hospital: localStorage.getItem("current_hospital")
            ? JSON.parse(localStorage.getItem("current_hospital")).id
            : "",
          "language-code":
            this.translationService.getSelectedLanguage()["value"],
        },
      },
    });
    if (config.apiBase) {
      localStorage.setItem("apiBase", config.apiBase);
    }
  }
  // 'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11'

  signIn(signInData: CustomSignIn) {
    localStorage.clear();

    return this.post("v1/oauth/code", signInData);
  }

  signOut() {
    let obs: Observable<Response> = super.signOut();
    localStorage.clear();
    obs.subscribe(
      (res) => {
        if (this.authConfig.signedOutRedirect)
          this.myRouter.navigateByUrl(this.authConfig.signedOutRedirect);
      },
      (error) => {
        if (this.authConfig.signedOutRedirect)
          this.myRouter.navigateByUrl(this.authConfig.signedOutRedirect);
      }
    );

    return obs;
  }

  getHospitals() {
    return this.get("v1/me").map((response) => {
      return response.json().hospitals;
    });
  }

  getUser() {
    return this.get("v1/me").map((response) => {
      return response.json().user;
    });
  }

  validateTwoFactor(code) {
    return this.post("v1/validate_two_factor_authentication", {
      code: code,
    }).map((response) => {
      return response.json().user;
    });
  }

  requestPasswordReset(redirect_url, email) {
    return this.post("v1/auth/password", {
      redirect_url: redirect_url,
      email: email,
    }).map((response) => {
      return response.json();
    });
  }

  requestPasswordResetStep2(updatePasswordData: UpdatePasswordData, headers) {
    return this._http.put(
      localStorage.getItem("apiBase") + "/v1/auth/password",
      {
        password: updatePasswordData.password,
        password_confirmation: updatePasswordData.passwordConfirmation,
      },
      { headers: headers }
    );
  }

  changePassword(data, id) {
    return this.put(`v1/hospitals/users/${id}/change_password`, data).map(
      (response) => {
        return response.json();
      }
    );
  }

  resetHeader() {
    this.init(this.authConfig);
  }
}
