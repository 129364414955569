// IT
export const locale = {
  lang: "it",
  data: {
    MENU: {
      MEDICAL_RECORDS: "Documenti",
      REQUESTS: "Richieste",
      SETTINGS: "Impostazioni",
      PROFILE: "Profilo",
    },
    PAGES: {
      MEDICAL_RECORDS: {
        DROPDOWN_MENU: {
          PAPER_COPY: "Richiedi copia cartacea",
          DIGITAL_COPY: "Richiedi copia digitale",
          SHARE: "Condividi",
          PRINT: "Stampa",
          MANAGE_VISIBILITY: "Gestisci visibilità",
        },
        FILTER: {
          YEAR: "Anno documento",
          FOLDER_NUMBER: "Numero documento",
          RICOVER_DATE: "Data di ricovero",
          DISCHARGE_DATE: "Data di dimissione",
          HAS_PDF: "Scansionata",
        },
      },
      MEDICAL_RECORD_DETAIL: {
        TITLE: "Dettaglio documento",
      },
    },
    BUTTON: {
      ORDER: "Ordina",
      SEE: "Vedi",
      SEE_RETREATS: "Vedi ritiri",
      CONFIRMATION: "Conferma",
      CANCEL: "Annulla",
      SHOW: "Visualizza",
      SHOW_ALL: "Visualizza tutte",
      SEARCH: "Ricerca",
      EDIT: "Modifica",
      PRINT_FOLDER: "Stampa cartella",
      PRINT_DOCUMENTS: "Stampa documenti",
      PRINT_FOLDERS: "Stampa cartella/e",
      DOWNLOAD_FOLDERS: "Scarica cartella/e",
      DOWNLOAD_FOLDER: "Scarica cartella",
      PRINT_INVOICES: "Stampa fattura/e",
      PRINT_ADDRESS: "Stampa indirizzo/i",
      CHANGE_STATE: "Cambia stato",
      CANCEL_REQUEST: "Annulla richiesta",
      REMOVE_FOLDER: "Rimuovi cartella",
      SHOW_DETAIL: "Visualizza dettaglio",
      SAVE: "Salva",
      REMOVE: "Elimina",
      CONTINUE: "Prosegui",
      CANCEL_CHANGED: "Annulla modifiche",
      CONFIRM_DELITION: "Conferma eliminazione",
      DOWNLOAD_PDF: "Scarica PDF",
      PRINT_ALL: "Stampa Tutti",
      DIAGNOSTICS: "Diagnostica",
    },
    AUTH: {
      LOGIN: {
        TITLE: "Accedi",
        BUTTON: "Login",
      },
      FORGOT: {
        TITLE: "Hai dimenticato la password?",
        DESC: "Ti verrà inviata un'email con le istruzioni per reimpostare la password.",
      },
      INPUT: {
        EMAIL: "Email",
        PASSWORD: "Password",
      },
    },
    DASHBOARD: {
      TITLE: "Dashboard",
      LAST_UPDATE: "Ultimo aggiornamento:",
      FIRST_BLOCK:
        "Cartelle cliniche in ospedale<br> per cui è stata già effettuata una richiesta",
      PATIENT_REQUESTS: {
        TITLE: "Richieste pazienti effettuate",
        FIRST_LINE: "Ultimi 12 mesi",
        SECOND_LINE: "Richieste da evadere",
        HOSPITAL: {
          TITLE: "In ospedale",
          FIRST_LINE: "Da preparare",
          SECOND_LINE: "Da ritirare",
        },
      },
      NORMADEC_REQUESTS: {
        TITLE: "Richieste a Normadec",
        FIRST_LINE: "originali",
        SECOND_LINE: "CD",
        THIRD_LINE: "riscansioni",
        FOURTH_LINE: "integrazioni",
      },
      FOURTH_BLOCK: "Cartelle originali <br> da restituire a Normadec",
      FIFTH_BLOCK: {
        TITLE: "Prossimo ritiro",
        FIRST_LINE: "cartelle cliniche",
        SECOND_LINE: "in",
        THIRD_LINE: "scatole pronte",
      },
      SIXTH_BLOCK: "Scatole rimaste",
    },
    REQUESTS: {
      TITLE: "Richieste copia cartacea",
      PATIENT: "Paziente",
      PATIENT_SURNAME: "Cognome paziente",
      PATIENT_NAME: "Nome paziente",
      PATIENT_TAX_CODE: "Codice fiscale paziente",
      REQUESTED_PERIOD: "Periodo richiesta",
      SOLVENT: "Solvente",
      SOLV: "Solv.",
      ALL: "Tutti",
      ALL_F: "Tutte",
      STATUS_REQUEST: "Stato richiesta",
      DELIVERY_TYPE: "Tipo consegna",
      JUST_REMINDERS: "Solo solleciti",
      APPLICANT: "Richiedente",
      APPLICANT_SURNAME: "Cognome richiedente",
      APPLICANT_NAME: "Nome richiedente",
      NUMBER_REQUEST: "Numero richiesta",
      NUM_REQUEST: "N.ro richiesta",
      MERGE_REQUESTS: "Unisci richieste",
      PRINT_REQUESTS: "Stampa richieste",
      SEND_REQUESTS: "Spedisci richieste",
      REQUESTED_DATE: "Data richiesta",
      DELIVERY: "Consegna",
      PRICE: "Prezzo",
      FOLDER_CODE: "Cod cartella",
      FOLDER_CODES: "Codici cartelle",
      NUMBER_PAGES: "Numero pagine",
      PRIORITY: "Priorità",
      HIGH: "Alta",
      NORMAL: "Normale",
      DOMICILE: "Domicilio",
      OPERATOR: "Operatore",
      CONTENT: "Contenuto",
      POSITION: "Posizione",
      RESCAN_FOLDER: "Riscansione intera cartella",
      REQUEST_DETAIL: {
        TITLE: "Dettaglio richiesta",
        ID_REQUEST: "ID Richiesta",
        TYPE_REQUEST: "Tipo Richiesta",
        TERRITORIAL_UNITY: "Unità territoriale",
        INSURANCE: "Assicurazione",
        NOTE: "Note",
        NO_NOTE: "Nessuna nota",
        AMOUNTS_DETAIL: "Dettaglio importi",
        SHIPMENT: "Spedizione",
        TOTAL: "Totale",
        TOTAL_IVA: "Totale + IVA",
        BILLING_TYPE: "Tipo fatturazione",
        BILLING_INFO: "Dati fatturazione",
        PHONE: "Tel",
        MOBILE_PHONE: "Cell",
        ADDRESS: "Indirizzo",
        SHIPPING_DATA: "Dati spedizione",
      },
      MEDICAL_RECORDS: {
        "": "Cartelle Cliniche",
        TITLE: "Cartelle Cliniche",
        PAGES: "Pagine",
        ACTUAL_POSITION: "Posizione attuale",
        BOX_POSITION: "Posizione scatola",
        AISLE: "Corridoio",
        ARCHIVE_CABINET: "Scaffale",
        SHELF: "Ripiano",
        WAREHOUSE: "Magazzino",
        PALLET: "Bancale",
      },
      OTHER_DOCUMENTS: "Altri documenti",
      NO_DOCUMENTS: "Nessun documento disponibile",
      SHIPPING_DETAIL: "Dettaglio spedizione",
      NO_DATA: "Nessun dato disponibile",
      LOG: "Log attività",
      MEDICAL_FOLDER: "cartella clinica",
      MEDICAL_FOLDERS: "cartelle cliniche",
      COPY_MEDICAL_FOLDER: "copia cartella clinica",
      RETURN_MEDICAL_FOLDER: "riconsegna cartella clinica",
      RISCAN_MEDICAL_FOLDER: "riscansione cartella clinica",
      REQUEST_MEDICAL_FOLDER: "richiesta cartella clinica",
      REQUEST_CD: "richiesta cd",
      ON_CD: "in un CD",
      MEDICAL_RECORD_TO_BE_RESCAN: "Cartella clinica da riscansionare",
      DESC_1:
        "Questa richiesta è intestata all'ospedale. Le fatture ed i DDT vengono gestiti in modo cumulativo, unendo più richieste.",
      DESC_2: "Clicca qui per accedere all'area fatture",
      DESC_3: "clicca qui per accedere all'area DDT",
      CARRIER: "Corriere",
      STATE: "Stato",
      REQUEST_SHIPPING: "Richiedi spedizione",
      NO_RESULTS_FOUND: "Nessun risultato trovato",
      DELIVERY_METHOD_TYPE: {
        OSPEDALE: "Ospedale",
        DOMICILIO: "Domicilio",
        DIGITALE: "Digitale",
      },
    },
    NEW_REQUEST: {
      STEP_2: "Aggiungi cartelle cliniche alla richiesta",
      STEP_3: "Invia richiesta",
      TITLE: "Riepilogo richiesta",
      ADD: "Aggiungi selezionate",
      BUTTON_DELETE: "Elimina selezionate",
      EDIT_REQUEST: "Modifica richiesta",
    },
    DIALOG_DELETE: {
      DESC: "Rimuovi cartella dalla richiesta",
      QUEST_1: "Sei sicuro di voler annullare la richiesta?",
      QUEST_2: "Sei sicuro di voler rimuovere la cartella?",
      ADD_NOTA: "Inserisci nota",
    },
    DIALOG_PICKUP: {
      TITLE: "Dettaglio ritiro",
      RETREAT_ON: "ritiro del",
      AT: "presso",
      ORIGINAL_FOLDER: "cartelle originali (restituzione)",
      INTEGRATIONS: "integrazioni",
      INTEGRATION: "integrazione",
      TO_BE_DELIVERED: "Da consegnare",
      COPY_MEDIAL_FOLDER: "copia cartella - Richiesta paziente",
      DESC: "Non è ancora presente un dettaglio per questo ritiro",
      PRINT_DOCUMENT: "Stampa documento consegna",
    },
    DIALOG_BOX: {
      TEXT_1: "cartelle verranno inserite nella scatola",
      TEXT_2: "cartelle con dati o anagrafica mancanti",
      TEXT_3:
        "cartelle con associazione scatola mancante (richiesta intervento operatore)",
      TEXT_4: "cartelle già associate a una scatola, oppure scatola chiusa",
    },
    FOLDER: {
      FOLDER_DETAIL: "Dettaglio documento",
      SEARCH_FOLDER: "Ricerca documento",
      FOLDER_DATA: "Dati documento",
      ASSOCIATED_REQUEST: "Richiesta associata",
      ASSOCIATED_REQUESTS: "Richieste associate",
      FOLDER_CODE: "Codice documento",
      HOSPITALIZATION_DATE: "Data di ricovero",
      DISCHARGE_DATE: "Data di dimissione",
      FOLDER_STATUS: "Stato documento",
      BOX_CODE: "Codice scatola",
      SCANNED: "Scansionata",
      YEAR_FOLDER: "Anno Documento",
      NUM_FOLDER: "N.ro Documento",
      NAME_SURNAME: "Nome Cognome",
      HOSP_DATE: "Data di ricovero",
      DISC_DATE: "Data di dimissione",
      LOCATION: "Ubicazione",
      ORIGINAL_FOLDERS: "cartelle originali",
      ORIGINAL_FOLDER: "cartella originale",
      FOLDER_POSITION: "Posizione cartella",
      DISCHARGE_DEPARTMENT: "Reparto di dimissione",
      NO_PDF: "Non è presente il PDF del documento",
      DATE_OF_BIRTH: "Data di<br>nascita",
      ENTRY_DATE: "Data di<br>ingresso",
      TYPOLOGY: "Tipologia",
      TYPE: {
        CARTELLA: "Cartella",
        REFERTO: "Referto",
      },
    },
    PICKUP: {
      ADD_PICKUP: "Aggiungi ritiro",
      BOXES_PICKUP: "N.ro scatole<br>da ritirare",
      FOLDERS_PICKUP: "N.ro cartelle<br>da ritirare",
      ORIGINAL_FOLDERS_PICKUP: "N.ro cartelle originali<br>da ritirare",
      FOLDERS_DELIVERY: "N.ro copia cartelle<br>da consegnare",
      BOXES_REQUIRED: "N.ro scatole<br>richieste",
      MANUAL_ENTRY: "Inserimento manuale",
      SAVE: "Salva contenuto scatola",
      BOXES_PREPARATION: "Scatole in preparazione",
      BOXES_READY: "Scatole pronte per il ritiro",
      NEXT_DATE: "Data prossimo ritiro",
      NO_WITHDRAWALS: "Non ci sono ritiri programmati",
      PRINT: "Stampa documento ritiri",
      CONTAINED_FOLDERS: "Cartelle contenute",
    },
    COMMON: {
      FILTERS: "Filtri",
      HOSPITAL: "Ospedale",
      NUM_FOLDER: "Numero cartella",
      YEAR_FOLDER: "Anno documento",
      FOLDER: "Cartella",
      FOLDERS: "Cartelle",
      BOX: "scatola",
      BOXES: "scatole",
      ACTIONS: "Azioni",
      ORPHAN: "orfanello",
      ORPHANS: "orfanelli",
      YES: "Si",
      NO: "No",
      INVOICE: "Fattura",
      SURNAME: "Cognome",
      NAME: "Nome",
      DATE_OF_BIRTH: "Data di nascita",
      FISCAL_CODE: "Codice fiscale",
      DATE: "Data",
      CONFIRMED_REQUEST: "Richiesta confermata",
      CONTENT_REQUEST: "Contenuto richiesta",
      SOLVENT_PATIENT: "Paziente solvente",
      SELECT_INSTITUTION: "Seleziona ente",
      DELIVERY_METHOD: "Modalità di consegna",
      TERM_OF_PAYMENTS: "Modalità di pagamento",
      P0: "Contrassegno",
      P1: "Allo sportello",
      P2: "Fatturazione a fine mese",
      REQUIRED_PRIORITY: "Priorità richiesta",
      SHIPPING_DATA: "Dati di spedizione",
      BILLING_INFORMATION: "Dati di fatturazione",
      DATA_APPLICANT: "Dati del richiedente",
      DOCUMENT_TYPE: "Tipo documento",
      AS: "In qualità di",
      COST_REQUEST: "Costo richiesta",
      TOT_FOLDERS: "Totale cartelle",
      COST_SHIPPING: "Costo spedizione",
      REQUEST_SENT: "Richiesta inviata",
      STEP_3_TEXT: "La tua richiesta è stata inviata con il codice",
      BACK_TO: "Torna all'elenco richieste",
      NEW_REQUEST: "Nuova Richiesta",
      FOLDER_IMPORT_SUCCESS: "cartelle importate con successo",
      LOAD_LIST: "Carica elenco",
      FOLDER_WITH_ERROR: "Cartelle con errori",
      HOSPITAL_CODE: "Cod. ospedale",
      DATA_FOLDER: "Dati Cartelle",
      N_FOLDER: "N.ro cartelle",
      LOOK_DETAIL: "Guarda dettaglio",
      SENDER_REMINDER: "Invia sollecito",
      DETAIL: "Dettaglio",
      REQUESTS_LIST: "Elenco richieste",
      NUM_DOCUMENT: "Numero di documento",
      ADD_NOTA_TO_REQUEST: "Aggiungi una nota alla richiesta",
      SURE: "Sei sicuro di voler inviare un sollecito?",
      VIEW_CALENDAR: "Visione calendario",
      VIEW_LIST: "Visione lista",
      DELETE_BOX: "Elimina scatola",
      SURE_DELETE_BOX: "Sei sicuro di voler eliminare la scatola?",
      CLOSE_BOX: "Chiudi scatola",
      REOPEN_INSERTION: "Riapri inserimento",
      SEE_FOLDERS: "Vedi cartelle",
      MOVE_TO_PROCESSING: "Sposta in lavorazione",
      NEAT_BOXES: "Scatole ordinate",
      FAILED_ADD_FOLDER: "Impossibile aggiungere la cartella",
      NO_ADD_FOLDER:
        "Dati del paziente non presenti. <br>La cartella non può essere aggiunta alla richiesta.",
      NO_SOLVENT_PATIENT:
        'Non è possibilie impostare "Paziente Solvente" se è presente più di una cartella nella richiesta.',
      DATA_SUMMARY: "Riepilogo dati",
      //	CHECK_PATIENT_ALERT: 'Potrebbero verificarsi casi di omonimia. <b>Controlla il codice fiscale.</b>',
      CHECK_PATIENT_ALERT:
        "Prima di confermare l'inserimento della richiesta controllare la corrispondenza dei dati, in particolare Codice Fiscale e Data di Nascita.",
      INTEGRATION: "Integrazione",
      ATTENTION: "Attenzione",
    },
    USER: {
      USER_LIST: "Lista utenti",
      ADD_USER: "Aggiungi utente",
      SURNAME: "Cognome",
      NAME: "Nome",
      PHONE: "Cellulare",
      ROLE: "Ruolo",
      ACTIONS: "Azioni",
      NO_USER_FOUND: "Nessun utente trovato",
      EDIT_USER: "Modifica utente",
      DELETE_USER: "Elimina utente",
      USER_DATA: "Dati utente",
      CREATE_USER: "Crea utente",
      UPDATE_USER: "Aggiorna utente",
      UDPATE_PROFILE: "Aggiorna profilo",
      PHONE_ONLY_NUMBER: "Cellulare (solo numeri)",
      CONFIRM_PASSWORD: "Conferma password",
      NATION: "Nazione",
      CITY: "Città",
      PROVINCE: "Provincia",
      REGION: "Regione",
      TELEPHONE: "Telefono",
      NOTE_PHONE: "Note telefono",
      SAFE_TO_DELETE: "Sei sicuro di voler eliminare l'utente",
    },
    BOX_REQUEST: {
      TEXT_1: "N.ro scatole in ospedale",
      TEXT_2: "N.ro scatole ordinate",
      TEXT_3: "Richiesta numero scatole",
    },
    REQUEST_HOSPITAL: {
      TEXT_1: "Elenco Richieste Cartelle Cliniche Originali",
      TEXT_2: "Elenco Richieste CD",
      TEXT_3: "Elenco Richieste Ri-scansione",
      TEXT_4: "Elenco Richieste Integrazione",
      TEXT_5: "Elenco Richieste Riconsegna Originale",
      TEXT_6: "Elenco Richieste Riconsegna Originale (con riscansione)",
      TEXT_7: "Elenco Richieste Scatole",
      TEXT_8: "Elenco richieste integrazione orfanelli",
    },
    DIALOG_MORE_FOLDER: {
      TITLE: "Conferma richiesta",
      TEXT: "Attenzione, stai effettuando una richiesta con più di una cartella, <b>inserisci una nota con la motivazione.</b>",
    },
  },
};
