import { AuthGuard } from "./api/auth.guard";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import "hammerjs";
import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { NgxPermissionsModule } from "ngx-permissions";

import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { LayoutModule } from "./content/layout/layout.module";
import { PartialsModule } from "./content/partials/partials.module";
import { CoreModule } from "./core/core.module";
import { AclService } from "./core/services/acl.service";
import { LayoutConfigService } from "./core/services/layout-config.service";
import { MenuConfigService } from "./core/services/menu-config.service";
import { PageConfigService } from "./core/services/page-config.service";
import { UserService } from "./core/services/user.service";
import { UtilsService } from "./core/services/utils.service";
import { ClassInitService } from "./core/services/class-init.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { GestureConfig, MatProgressSpinnerModule } from "@angular/material";
import { OverlayModule } from "@angular/cdk/overlay";

import { MessengerService } from "./core/services/messenger.service";
import { ClipboardService } from "./core/services/clipboard.sevice";

import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { LayoutConfigStorageService } from "./core/services/layout-config-storage.service";
import { LogsService } from "./core/services/logs.service";
import { QuickSearchService } from "./core/services/quick-search.service";
import { SubheaderService } from "./core/services/layout/subheader.service";
import { HeaderService } from "./core/services/layout/header.service";
import { MenuHorizontalService } from "./core/services/layout/menu-horizontal.service";
import { MenuAsideService } from "./core/services/layout/menu-aside.service";
import { LayoutRefService } from "./core/services/layout/layout-ref.service";
import { SplashScreenService } from "./core/services/splash-screen.service";
import { ApiService, RoleGuardService } from "./api/api.module";
import { HttpModule } from "@angular/http";
import { UiModule } from "./ui/ui.module";
import { MAT_DATE_LOCALE } from "@angular/material";
import { MatListModule } from "@angular/material/list";
import { HttpInterceptorService } from "./services/base/http-Interceptor.service";
import { BackendService } from "./services/base/backend-caller.service";
import { LocalStorageService } from "./services/base/local-storage.service";
import { PreviousRouteService } from "./services/base/previous-route.service";
import { CanActivateUserLogged } from "./api/auth-guard";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    NgxPermissionsModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    HttpModule,
    UiModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    BackendService,
    LocalStorageService,
    PreviousRouteService,
    ApiService,
    RoleGuardService,
    AuthGuard,
    CanActivateUserLogged,
    AclService,
    LayoutConfigService,
    LayoutConfigStorageService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    UserService,
    UtilsService,
    ClassInitService,
    MessengerService,
    ClipboardService,
    LogsService,
    QuickSearchService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    SubheaderService,
    HeaderService,
    MenuHorizontalService,
    MenuAsideService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig,
    },
    { provide: MAT_DATE_LOCALE, useValue: "it" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
