import { AuthGuard } from "./auth.guard";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthRoutingModule } from "./api-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApiService, ApiServiceConfig } from "./api.service";
import { LoginComponent, DialogResetPwd } from "./login/login.component";
import { ResetPwdComponent } from "./reset-pwd/reset-pwd.component";
import { RoleGuardService } from "./role-guard.service";
import { UiModule } from "../ui/ui.module";
import { AuthNoticeComponent } from "./auth-notice/auth-notice.component";
import { SpinnerButtonModule } from "../content/partials/content/general/spinner-button/spinner-button.module";
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatCheckboxModule,
  MatTabsModule,
} from "@angular/material";
import { MatListModule } from "@angular/material/list";
import { ChangePasswordComponent } from "./change-password/change-password.component";
@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    SpinnerButtonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatListModule,
    MatTabsModule,
  ],
  declarations: [
    LoginComponent,
    ResetPwdComponent,
    AuthNoticeComponent,
    DialogResetPwd,
    ChangePasswordComponent,
  ],
  exports: [],
  entryComponents: [DialogResetPwd],
})
export class ApiModule {}

export {
  ApiService,
  RoleGuardService,
  LoginComponent,
  ApiServiceConfig,
  ChangePasswordComponent,
  AuthGuard,
};
export { SignInData, UserData } from "angular2-token";
