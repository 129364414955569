import { Observable } from "rxjs";
import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "../services/base/local-storage.service";
import { PathPage } from "../utils/path-page";

@Injectable()
export class CanActivateUserLogged implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}
  canActivate(): Observable<boolean> | boolean {
    if (this.localStorageService.get("accessToken")) return true;
    else {
      this.router.navigate([`/${PathPage.AUTH}/${PathPage.LOGIN}`]);
      return false;
    }
  }
}

@Injectable()
export class CanNotActivateUserLogged implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}
  canActivate(): Observable<boolean> | boolean {
    if (this.localStorageService.get("accessToken")) {
      this.router.navigate(["/home"]);
      return false;
    } else return true;
  }
}
