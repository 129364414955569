import { Component, OnInit, ViewContainerRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { UpdatePasswordData } from 'angular2-token';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import { SpinnerButtonOptions } from '../../content/partials/content/general/spinner-button/button-options.interface';
import { NgForm } from '@angular/forms';
import * as objectPath from 'object-path';
import {Headers} from '@angular/http';
import { AuthNoticeService } from '../../api/auth-notice/auth-notice.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss']
})
export class ResetPwdComponent implements OnInit {
  //public _resetPasswordData: FormControl = <FormControl>{};
  @ViewChild('f') f: NgForm;
  public loading = false;
  errors: any = [];
  headers:Headers;
  public _updatePasswordData: UpdatePasswordData = <UpdatePasswordData>{};
  spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'light-blue',
		spinnerColor: 'light',
		fullWidth: false
  };

  constructor(
    private _apiService : ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public authNoticeService: AuthNoticeService,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar) {
    }

    ngOnInit() {
      this.route.queryParams.subscribe( params => {
        this.headers = new Headers();
        this.headers.append('access-token', params["access-token"]);
        this.headers.append('client', params["client"]);
        this.headers.append('uid', params["uid"]);
        this.headers.append('token-type', "Bearer");
      })
    }

    validate(f: NgForm) {
      if (f.form.status === 'VALID') {
        return true;
      }

      this.errors = [];
      if (objectPath.get(f, 'form.controls.password.errors.required')) {
        this.errors.push('La password è obbligatoria');
      }
      if (objectPath.get(f, 'form.controls.password_confirmation.errors.required')) {
        this.errors.push('La conferma password è obbligatoria');
      }
      if (objectPath.get(f, 'form.controls.password.errors.minlength')) {
        this.errors.push('La password deve avere almeno 8 caratteri');
      }
      if (objectPath.get(f, 'form.controls.password_confirmation.errors.minlength')) {
        this.errors.push('La conferma password deve avere almeno 8 caratteri');
      }
      if (objectPath.get(f, 'form.controls.password').value != objectPath.get(f, 'form.controls.password_confirmation').value) {
        this.errors.push('La password e conferma password non coincidono');
      }

      if (this.errors.length > 0) {
        this.authNoticeService.setNotice(this.errors.join('<br/>'), 'error');
        this.spinner.active = false;
      }

      return false;
    }

  //this.router.navigateByUrl('/email-sent');
  submit() {
		this.spinner.active = true;
		if (this.validate(this.f)) {

      this._apiService
      .requestPasswordResetStep2(this._updatePasswordData, this.headers)
      .subscribe(
        res => {
          this.spinner.active = false;
          this.showSnackBar(res.json().message);
          this.authNoticeService.setNotice(``, 'info');
          this.cdr.detectChanges();
          this.router.navigateByUrl('/');
        },
        error =>{
          this.authNoticeService.setNotice(``, 'info');
          this.spinner.active = false;
          if (error.json().errors) {
            this.showSnackBarError(error.json().errors.full_messages[0]);
          } else {
            this.showSnackBarError(error.json().errors[0]);
          }
          this.cdr.detectChanges();
        }
      )
		}
  }

  showSnackBar(msg){
    let config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.panelClass = 'success';
        config.duration = 5000;
        this.snackBar.open(msg, 'OK', config);
  }

  showSnackBarError(msg){
    let config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.panelClass = 'error';
        config.duration = 5000;
        this.snackBar.open(msg, 'OK', config);
  }



}
